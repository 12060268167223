import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-tanoariacoelho-adrego-com/src/components/layout.js";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import ContactsPage from "../components/contacts.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title={useIntl().formatMessage({
      id: "contactos.title"
    })} description={useIntl().formatMessage({
      id: "description"
    })} image={'https://www.tanoariacoelho-adrego.com' + ogImage} siteUrl={'https://www.tanoariacoelho-adrego.com' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1><FormattedMessage id="contactos.title" mdxType="FormattedMessage" /></h1>
      <strong><FormattedMessage id="title" mdxType="FormattedMessage" /></strong><br />
Rua da Carvalheira<br />
3885-721 Ovar<br />
Portugal<br />
Loc: 40.920796, -8.616684<br />
Phone: 256792428<br />
Email: s.pinto.coelho.adrego@hotmail.com<br />
International contact: Miguel Adrego: +351 912 940 143
    </MdxLayout>
    <div style={{
      maxWidth: 900,
      margin: '0 auto'
    }}>
    <ContactsPage mdxType="ContactsPage" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      